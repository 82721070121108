<template>
  <v-card class="mb-12 form-container fill-height" :loading="loading" :elevation="mdScreen ? 0: 2">
    <v-tabs v-model="editTabs">
      <v-tab>Profile</v-tab>
      <v-tab>Social</v-tab>
      <v-tab>Account</v-tab>
    </v-tabs>
    <v-divider class="tabber"></v-divider>
    <v-form ref='editProfile' v-model='valid' @submit.prevent='validate()' id='create-education-form' class='v-text-field--rounded form' loading>
      <v-card-text>
        <v-tabs-items v-model="editTabs">
          <v-tab-item>
            <v-row>
              <v-col>
                <v-text-field
                  ref='et_professional_title'
                  outlined
                  :rules="!isEmployer ? titleRules : []"
                  counter
                  label='Professional Title'
                  placeholder="Eg: Frontend Developer | Meditation Trainer"
                  hint="🔥 A small tagline about you!"
                  persistent-hint
                  v-model.trim='currentUser.profile.et_professional_title'></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="$apollo.queries.colleges.loading">
                <v-card>
                  <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                </v-card>
              </v-col>
              <v-col v-else>
                <v-autocomplete
                  ref="college"
                  auto-select-first
                  v-model="currentUser.profile.college[0]"
                  :items="colleges"
                  :loading="loading"
                  outlined
                  :rules="!isEmployer ? collegeRules : []"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  :hint="isEmployer ? 'We will add you in a College Alumni Group' : '⚡ Connect to skilled students in your college / university instantly!'"
                  label="College / University"
                  placeholder="Start typing to fetch your college"
                  prepend-inner-icon="mdi-school"
                  :menu-props="autocompleteMenuProps"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="$apollo.queries.skills.loading">
                <v-card>
                  <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                </v-card>
              </v-col>
              <v-col v-else>
                <v-autocomplete
                  ref="skills"
                  v-model="currentUser.profile.skill"
                  :items="skills"
                  :search-input.sync="searchInput"
                  @input="searchInput = null"
                  :loading="loading"
                  :rules="!isEmployer ? skillsRules : []"
                  :counter="50"
                  outlined
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  auto-select-first
                  small-chips
                  :hint="isEmployer ? 'Relevant Skills (Optional)' : '🛡 Add relevant skills so that people can reach you faster!'"
                  label="Skills"
                  :menu-props="autocompleteMenuProps"
                  placeholder="Start typing to fetch skills"
                  prepend-inner-icon="mdi-flask"
                  multiple
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="$apollo.queries.categories.loading">
                <v-card>
                  <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                </v-card>
              </v-col>
              <v-col v-else>
                <!--            Implement when all things are done -->
                <!--            <div v-for="(value, item, i) in categ" :key="i" class="mb-5">-->
                <!--              <p class="mb-0"><b>{{ item }}</b></p>-->
                <!--              <v-chip-group column active-class="success&#45;&#45;text" multiple v-model="user.selectedCategories[Object.keys(user.selectedCategories)[i]]">-->
                <!--                <v-chip v-for="(a, i) in value" :key="i" filter outlined label :value="a" filter-icon="mdi-bee">-->
                <!--                  {{ a.name }}-->
                <!--                </v-chip>-->
                <!--              </v-chip-group>-->
                <!--            </div>-->
                <!--          <v-chip-group active-class="success&#45;&#45;text" multiple v-model="userSelected">-->
                <!--            <v-chip v-for="(item, i) in userCategories" :key="i" filter outlined label :value="item.id" filter-icon="mdi-bee">-->
                <!--              {{ item.name }}-->
                <!--            </v-chip>-->
                <!--          </v-chip-group>-->
                <v-autocomplete
                  outlined
                  hide-selected
                  ref="category"
                  required
                  deletable-chips
                  :items="userCategories"
                  chips
                  v-model="userSelected"
                  item-value="id"
                  item-text="name"
                  :loading="loading"
                  :menu-props="autocompleteMenuProps"
                  multiple
                  eager
                  label="Select Categories / Topics" />

              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col :cols="12">
                <v-text-field
                  ref='linkedIn'
                  prefix="linkedin.com/in/"
                  prepend-inner-icon="mdi-linkedin"
                  outlined
                  label='LinkedIn Username'
                  placeholder="###"
                  v-model.trim='currentUser.meta.linkedin_profile'></v-text-field>
              </v-col>
              <v-col :cols="12">
                <v-text-field
                  ref='facebook'
                  prefix="facebook.com/"
                  prepend-inner-icon="mdi-facebook"
                  outlined
                  label='Facebook Username'
                  placeholder="###"
                  v-model.trim='currentUser.meta.facebook_profile'></v-text-field>
              </v-col>
              <v-col :cols="12">
                <v-text-field
                  ref='twitter'
                  outlined
                  prepend-inner-icon="mdi-twitter"
                  prefix="twitter.com/"
                  label="Twitter handle"
                  placeholder="###"
                  v-model.trim='currentUser.meta.twitter_profile'></v-text-field>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col :cols="12" :sm="6">
                <v-text-field
                  ref='first_name'
                  outlined
                  value="something"
                  :rules="required"
                  required
                  label='First Name'
                  v-model='currentUser.meta.first_name'></v-text-field>
              </v-col>
              <v-col :cols="12" :sm="6">
                <v-text-field
                  ref='last_name'
                  outlined
                  :rules="required"
                  label='Last Name'
                  v-model='currentUser.meta.last_name'></v-text-field>
              </v-col>
            </v-row>
<!--            <v-row>-->
<!--              <v-col :cols="12">-->
<!--                <v-text-field-->
<!--                  ref='email'-->
<!--                  outlined-->
<!--                  :rules="required"-->
<!--                  label='Email'-->
<!--                  v-model='currentUser.user_email'></v-text-field>-->
<!--              </v-col>-->
<!--            </v-row>-->
            <v-row>
              <v-col>
                <v-radio-group v-model="currentUser.meta.gender" row class="mt-0">
                  <v-radio label="Female" value="female"></v-radio>
                  <v-radio label="Male" value="male"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  ref='location'
                  v-model="currentUser.meta.location"
                  :search-input.sync="locationInput"
                  @input="locationInput = null"
                  auto-select-first
                  :items="items"
                  :rules="required"
                  :loading="loading"
                  outlined
                  hide-no-data
                  item-text="Description"
                  clearable
                  item-value="API"
                  persistent-hint
                  :hint="isEmployer ? '' : `People in your city can find you quickly 🔥`"
                  label="Location"
                  placeholder="Start typing to fetch your location"
                  prepend-inner-icon="mdi-map-marker"
                  :menu-props="autocompleteMenuProps"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-divider v-if="!$vuetify.breakpoint.smAndDown"></v-divider>
      <v-footer :padless="true" :fixed="$vuetify.breakpoint.smAndDown">
        <v-card-text>
          <v-btn
            type='submit'
            color='secondary'
            block
            depressed
            x-large
            :disabled='loading || !valid'
            :loading='loading'>
            <template v-slot:loader>
              <span>Updating...</span>
            </template>
            <span class="mr-1">Update</span>
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-text>
      </v-footer>
    </v-form>
  </v-card>
</template>

<script>
import breakPoints from '../../mixins/breakPoints'
import autocomplete from '../../mixins/autocomplete'
import mapsMixin from '../../mixins/mapsMixin'
const COLLEGES = require('../../graphql/queries/colleges.graphql')
const SKILLS = require('../../graphql/queries/skills.graphql')
const CATEGORIES = require('../../graphql/queries/categories.graphql')
const UPDATE_USER = require('../../graphql/updateUser.graphql')

export default {
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      valid: true,
      editTabs: null,
      loading: false,
      required: [v => (!!v) || 'Field is required'],
      error: null,
      searchInput: null,
      titleRules: [v => v.length < 81 || '80 characters maximum length'],
      collegeRules: [v => (!!v) || 'Field is required'],
      skillsRules: [
        v => (v.length > 0) || 'Field is required',
        v => v.length < 51 || 'Cannot select more than 50 skills'
      ],
      items: [this.currentUser.meta.location || ''],
      newCategories: []
    }
  },
  apollo: {
    colleges: {
      query: COLLEGES
    },
    skills: {
      query: SKILLS
    },
    categories: {
      query: CATEGORIES
    }
  },
  computed: {
    userCategories () {
      return this.categories && this.categories.map(item => item.term)
    },
    userSelected: {
      get () {
        return this.currentUser.profile.project_category.map(item => item.id) || []
      },
      set (val) {
        this.newCategories = val
        // this.currentUser.profile.project_category = [val]
      }
    },
    isEmployer () {
      return this.currentUser.role === 'employer'
    }
  },
  methods: {
    validate () {
      if (this.$refs.editProfile.validate()) {
        this.submitUser()
      }
    },
    async submitUser () {
      this.loading = true
      let college = null
      let skills = []
      if (!this.isEmployer) {
        college = typeof this.currentUser.profile.college[0] === 'string' ? this.currentUser.profile.college[0] : this.currentUser.profile.college[0].id
        skills = typeof this.currentUser.profile.skill[0] === 'string' ? this.currentUser.profile.skill : this.currentUser.profile.skill.map(skill => skill.id)
      }
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_USER,
          variables: {
            data: {
              first_name: this.currentUser.meta.first_name,
              last_name: this.currentUser.meta.last_name,
              location: this.currentUser.meta.location,
              college: college,
              skills: skills,
              // user_email: this.currentUser.user_email,
              categories: this.newCategories,
              gender: this.currentUser.meta.gender,
              twitter_profile: this.currentUser.meta.twitter_profile,
              facebook_profile: this.currentUser.meta.facebook_profile,
              linkedin_profile: this.currentUser.meta.linkedin_profile,
              professional_title: this.currentUser.profile.et_professional_title
            }
          }
        })
        await this.$emit('updateDone')
        await this.$router.replace({ name: 'myProfile' })
      } catch (e) {
        console.log(e)
        this.error = e
      } finally {
        this.loading = false
      }
    }
  },
  mixins: [breakPoints, autocomplete, mapsMixin]
}
</script>
<style lang='scss' scoped>
  .form-container {
    border-radius: 4px;
  }
</style>
