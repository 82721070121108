<template>
  <v-dialog
    v-model="dialog"
    overlay-opacity="0.9"
    max-width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" outlined class="mr-4 mb-5 mb-md-0" :fab="$vuetify.breakpoint.xs" large  v-if="isGuest" :to="{name: 'login'}">
        <v-icon>mdi-lock</v-icon> <span class="ml-1" v-show="!$vuetify.breakpoint.xs">Message</span>
      </v-btn>
      <v-btn color="primary" outlined class="mr-4 mb-5 mb-md-0" :fab="$vuetify.breakpoint.xs" large v-bind="attrs" v-on="on" v-else-if="!sent">
        <v-icon>mdi-email</v-icon> <span class="ml-1" v-show="!$vuetify.breakpoint.xs">Message</span>
      </v-btn>
      <v-btn color="success" outlined class="mr-4 mb-5 mb-md-0" large :fab="$vuetify.breakpoint.xs" v-else>
        <v-icon>mdi-check</v-icon> <span class="ml-1" v-show="!$vuetify.breakpoint.xs">Message Sent</span>
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title primary-title>
        Send Direct Message to {{ name }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-6">
        <v-form ref="messageForm" v-model="valid" @submit.prevent="validate()" id="register-form" class="v-text-field--rounded form">
          <v-textarea counter v-model="message" persistent-hint hint="Message will be sent as 1 single paragraph" outlined :rules="messageRules" label="Write your message here" no-resize class="mb-5"></v-textarea>
          <div v-if="!registerStatus" class="mb-3 error--text">Please confirm your email to perform this action.</div>
          <v-btn @click="validate" block color="secondary" large :disabled="!valid || loading || !registerStatus">
            Send Message <v-icon small>mdi-arrow-right</v-icon>
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import THREAD from '../../graphql/queries/thread.graphql'
import ADD_COMMENT from '../../graphql/mutations/addComment.graphql'
export default {
  apollo: {
    thread: {
      query: THREAD,
      fetchPolicy: 'no-cache', // cache for this query only
      manual: true,
      async result (data) {
        await this.submitMessage(data.data.thread)
      },
      skip () {
        return !this.init
      },
      variables () {
        return {
          userId: this.id
        }
      }
    }
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    registerStatus: {
      type: Boolean,
      default: false
    },
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      dialog: false,
      loading: false,
      valid: true,
      message: '',
      init: false,
      sent: false,
      messageRules: [
        v => v.length < 281 || '280 characters limit',
        v => (!!v) || 'Field is required'
      ]
    }
  },
  computed: {
    isGuest () {
      return Object.keys(this.currentUser).length === 0 && this.currentUser.constructor === Object
    }
  },
  methods: {
    validate () {
      if (this.$refs.messageForm.validate()) {
        this.chatInit()
      }
    },
    async chatInit () {
      this.loading = true
      this.init = true
      await this.$apollo.queries.thread.refresh()
    },
    async submitMessage (id) {
      try {
        await this.$apollo.mutate({
          mutation: ADD_COMMENT,
          variables: {
            threadId: id,
            message: this.message
          }
        })
        this.dialog = false
        this.sent = true
        this.message = ''
      } catch (e) {
        console.log(e)
        this.error = e
      } finally {
        this.init = false
        this.loading = false
      }
    }
  }
}
</script>
