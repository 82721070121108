<template>
  <div>
    <v-card v-if="$apollo.queries.currentUser.loading" flat class="py-sm-5 px-sm-1 pa-0">
      <v-skeleton-loader type="list-item-two-line" class="mb-5"></v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader type="list-item-two-line" class="my-5"></v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader type="list-item-two-line" class="my-5"></v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader type="list-item-two-line" class="my-5"></v-skeleton-loader>
    </v-card>
    <div v-else>

      <v-card flat v-for="(item, i) in projects" :key="i" class="pa-0" :to="{ name: 'project', params: { slug: item.post.name,  id: item.post.id } }">
          <v-card-title class="text-break">{{ item.post.title }}</v-card-title>
          <v-card-subtitle class="pb-1 text--primary"><span class="text--secondary">Posted by</span> {{ item.post.authorName }}</v-card-subtitle>
          <v-card-text>
            <span class="success--text text-break font-weight-medium">Bookmarked {{ item.date | moment('from', 'now') }}</span>
          </v-card-text>
          <v-divider v-show="i < projects.length - 1"></v-divider>
          <v-divider v-show="i < projects.length - 1"></v-divider>
      </v-card>
      <v-card-text class="text-h6" v-if="projects.length === 0">
        <div>
          😐 No Bookmarks Yet!
        </div>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import BOOKMARKED_PROFILES from '../../graphql/queries/bookmarkedProfiles.graphql'
export default {
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  apollo: {
    currentUser: {
      query: BOOKMARKED_PROFILES,
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    projects () {
      return this.currentUser && this.currentUser.myBookmarks.filter(bookmark => bookmark.post !== null)
    }
  }
}
</script>
