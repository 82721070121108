<template>
  <v-container fluid class="pa-sm-5 px-1 mobile-height">
    <skeleton v-show="!isCurrentUser" />
    <profile-component :user="currentUser" :currentUser="currentUser" v-if="isCurrentUser && !editMode" :is-me="true" @editClicked="$emit('reFetchCurrentUser')" />
    <edit :current-user="currentUser" v-if="isCurrentUser && editMode" @updateDone="$emit('reFetchCurrentUser')"/>
    <v-card v-if="!editMode && isCurrentUser">
      <v-tabs v-model="tab" show-arrows>
        <v-tab :to="{name: 'myProfile'}">
<!--          <v-badge-->
<!--            inline-->
<!--            bordered-->
<!--            color="primary"-->
<!--            :content="currentUser.counts.posts"-->
<!--            v-show="currentUser.counts.posts"-->
<!--          >-->
<!--            Posts-->
<!--          </v-badge>-->
          <span>
            Posts
          </span>
        </v-tab>
        <v-tab :to="{name: 'myApplications'}" replace>
<!--          <v-badge-->
<!--            inline-->
<!--            bordered-->
<!--            color="primary"-->
<!--            :content="currentUser.counts.interested"-->
<!--            v-show="currentUser.counts.interested"-->
<!--          >-->
<!--            Interested-->
<!--          </v-badge>-->
          <span>
            Interested
          </span>
        </v-tab>
        <v-tab :to="{name: 'myBookmarkedProjects'}" replace>
<!--          <v-badge-->
<!--            inline-->
<!--            bordered-->
<!--            color="primary"-->
<!--            :content="currentUser.counts.myBookmarks"-->
<!--            v-show="currentUser.counts.myBookmarks"-->
<!--          >-->
<!--            Bookmarks-->
<!--          </v-badge>-->
          <span>
            Bookmarks
          </span>
        </v-tab>
        <v-tab :to="{name: 'mySavedProfiles'}" replace>
<!--          <v-badge-->
<!--            inline-->
<!--            bordered-->
<!--            color="primary"-->
<!--            :content="currentUser.counts.myBookmarks"-->
<!--            v-show="currentUser.counts.myBookmarks"-->
<!--          >-->
<!--            Saved Profiles-->
<!--          </v-badge>-->
          <span>
            Saved profiles
          </span>
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <posts-tab flat v-if="$route.name === 'myProfile'" :id="currentUser.ID" />
      <applications-tab flat v-if="$route.name === 'myApplications'" :id="currentUser.ID" />
      <bookmarks-tab flat v-if="$route.name === 'myBookmarkedProjects'" :id="currentUser.ID" />
      <saved-profiles-tab flat v-if="$route.name === 'mySavedProfiles'" :id="currentUser.ID" />
    </v-card>
  </v-container>
</template>

<script>
import ProfileComponent from '../components/ProfileComponent'
import Edit from '../components/ProfileComponent/Edit'
import PostsTab from '../components/ProfileComponent/PostsTab'
import ApplicationsTab from '../components/ProfileComponent/ApplicationsTab'
import Skeleton from '../components/Utils/Skeleton'
import SavedProfilesTab from '../components/ProfileComponent/SavedProfilesTab'
import BookmarksTab from '../components/ProfileComponent/BookmarksTab'
// import PROJECTS from '../graphql/projects.graphql'
// import PROJECTS from '../graphql/projects.graphql'

export default {
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      tab: 0,
      update: 0
    }
  },
  computed: {
    isCurrentUser () {
      return this.currentUser.ID
    },
    editMode () {
      return this.$route.name === 'editProfile'
    }
  },
  methods: {
    handleTabs () {
      if (this.$route.name === 'myApplications') {
        this.tab = 1
      }
    }
  },
  components: {
    BookmarksTab,
    SavedProfilesTab,
    ProfileComponent,
    Edit,
    ApplicationsTab,
    PostsTab,
    Skeleton
  },
  beforeMount () {
    this.handleTabs()
  }
}
</script>

<style scoped>

</style>
