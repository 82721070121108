<template>
  <div>
    <v-card v-if="$apollo.queries.user.loading" flat  class="py-sm-5 px-sm-1 pa-0">
      <v-skeleton-loader type="list-item-two-line" class="mb-5"></v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader type="list-item-two-line" class="my-5"></v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader type="list-item-two-line" class="my-5"></v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader type="list-item-two-line" class="my-5"></v-skeleton-loader>
    </v-card>
    <div v-else>
      <v-card v-for="(item, i) in user.posts" :key="i" :to="{ name: 'project', params: { slug: item.name,  id: item.id } }" flat :tile="i !== user.posts.length - 1" hover>
        <v-card-title class="text-break pb-1">{{ item.title }}</v-card-title>
        <v-card-text class="pb-2"><span class="success--text text-break font-weight-medium">Posted {{ item.date | moment('from', 'now') }}</span></v-card-text>
        <v-card-subtitle class="pt-0"><vue-markdown :source="item.excerpt"></vue-markdown></v-card-subtitle>
        <v-card-text>
          <div class="post-meta mt-n2">
            <span><v-icon small color="pink">mdi-heart</v-icon>{{ item.counts.interested }}</span>
            <span><v-icon small color="green">mdi-comment-multiple</v-icon>{{ item.counts.comments }}</span>
            <span><v-icon small color="blue">mdi-bookmark</v-icon>{{ item.counts.bookmarks }}</span>
          </div>
        </v-card-text>
        <v-divider v-show="i < user.posts.length - 1"></v-divider>
        <v-divider v-show="i < user.posts.length - 1"></v-divider>
      </v-card>
      <v-card-text class="text-h6" v-if="user.posts.length === 0">
        <div>
          😐 No Published Posts Yet!
        </div>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import POSTS from '../../graphql/queries/posts.graphql'
export default {
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  apollo: {
    user: {
      query: POSTS,
      fetchPolicy: 'network-only',
      variables () {
        return {
          id: this.id
        }
      },
      skip () {
        return !this.id
      }
    }
  }
}
</script>
