<template>
  <div>
    <v-card v-if="$apollo.queries.user.loading" flat>
      <v-skeleton-loader type="list-item-two-line" class="my-5"></v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader type="list-item-two-line" class="my-5"></v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader type="list-item-two-line" class="my-5"></v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader type="list-item-two-line" class="my-5"></v-skeleton-loader>
    </v-card>
    <div v-else>
      <v-card v-for="(item, i) in user.interested" :key="i" :to="{ name: 'project', params: { slug: item.project.name,  id: item.project.id } }" flat :tile="i !== user.interested.length - 1">
        <v-card-title class="text-break">{{ item.project.title }}</v-card-title>
        <v-card-subtitle class="pb-1 text--primary"><span class="text--secondary">Posted by</span> {{ item.project.authorName }}</v-card-subtitle>
        <v-card-text>
          <span class="success--text text-break font-weight-medium">Applied {{ item.date | moment('from', 'now') }}</span>
          <div class="my-3 pl-3 text-h6 font-weight-medium message">{{ item.message }}</div>
        </v-card-text>
        <v-divider v-show="i < user.interested.length - 1"></v-divider>
        <v-divider v-show="i < user.interested.length - 1"></v-divider>
      </v-card>
      <v-card-text class="text-h6" v-if="user.interested.length === 0">
        <div>
          😐 You are not interested in any project.
        </div>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import USER_INTERESTED from '../../graphql/queries/userInterested.graphql'
export default {
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  apollo: {
    user: {
      query: USER_INTERESTED,
      fetchPolicy: 'network-only',
      variables () {
        return {
          id: this.id
        }
      }
    }
  }
}
</script>

<style scoped>
  .message {
    border-left: 3px solid;
  }
</style>
