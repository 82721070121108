export default {
  data () {
    return {
      locationInput: null
    }
  },
  computed: {
    locationService () {
      return this.$store.state.locationService
    }
  },
  methods: {
    displaySuggestions (predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.items = []
        return
      }
      this.items = predictions.map(prediction => prediction.description)
    }
  },
  watch: {
    'locationInput' (newValue) {
      if (newValue && newValue.length > 1) {
        this.locationService && this.locationService.getPlacePredictions({
          input: this.locationInput,
          types: ['(cities)']
        }, this.displaySuggestions)
      }
    }
  }
}
