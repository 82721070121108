<template>
  <v-card class="mb-5">
    <v-list two-line>
      <v-list-item class="px-5 d-flex flex-column flex-sm-row min-auto">
        <v-list-item-avatar size="100" v-if="user.meta" class="uploader" :color="user.meta.et_avatar_url ? '' : 'info'">
          <v-img :src="user.meta.et_avatar_url" :lazy-src="user.meta.et_avatar_url" v-if="user.meta.et_avatar_url"></v-img>
          <span class="white--text headline" v-else>{{ user.meta.first_name[0].toUpperCase() }}{{ user.meta.last_name[0].toUpperCase() }}</span>
          <div class="absolute d-flex justify-center" v-if="isMe">
            <v-progress-circular v-if="uploading" indeterminate size="100" color="info"></v-progress-circular>
          </div>
          <div class="dp-edit" v-if="isMe">
            <v-file-input accept="image/png, image/jpeg" :disabled="uploading" prepend-icon="mdi-square-edit-outline" loading hide-input @change="uploadImage($event)" class="file-upload"></v-file-input>
          </div>
        </v-list-item-avatar>
        <v-snackbar v-model="snackbar" app>
          {{ uploadMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
          </template>
        </v-snackbar>
        <v-list-item-content>
          <v-list-item-title class="d-flex align-center text-h6 justify-sm-space-between justify-center">
            <div class="mr-1">
              {{ user.meta.first_name }} {{ user.meta.last_name }}
              <v-icon color="success" class="ml-1" v-show="user.meta.final_status === 'confirm'">mdi-check-decagram</v-icon>
            </div>
            <v-btn small color="primary" outlined v-if="isMe && !$vuetify.breakpoint.xs" :to="{name: 'editProfile'}"><v-icon small>mdi-pencil</v-icon> <span class="ml-1">Edit Profile</span></v-btn>
          </v-list-item-title>
          <v-list-item-subtitle class="mt-2 font-weight-medium d-flex justify-sm-start justify-center" v-if="user.profile.college.length > 0"><v-icon small class="mr-1">mdi-school</v-icon>{{ user.profile.college[0].name }}</v-list-item-subtitle>
          <v-list-item-subtitle class="my-3 font-weight-medium d-flex justify-sm-start justify-center"><v-icon small>mdi-map-marker</v-icon>{{user.meta.location}}</v-list-item-subtitle>
          <v-list-item-subtitle class="font-weight-medium d-flex justify-sm-start justify-center"><v-icon small>mdi-calendar-month</v-icon><span class="ml-1">Joined {{ user.user_registered | moment('from', 'now') }}</span></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <div class="d-flex justify-space-between">
            <div class="d-flex">
              <send-message :name="user.meta.first_name" :id="user.ID" :registerStatus="registerStatus" :currentUser="currentUser"/>
              <div v-if="isGuest">
                <v-btn color="pink" outlined class="mb-5 mb-md-0 white--text" large :to="{name: 'login'}" :fab="$vuetify.breakpoint.xs">
                    <span><v-icon class="mr-1">mdi-lock</v-icon>
                      <span v-show="!$vuetify.breakpoint.xs">Bookmark Profile</span>
                    </span>
                </v-btn>
              </div>
              <div v-else>
                <v-btn color="pink" class="mb-5 mb-md-0 white--text" large @click="toggleBookmark('delete')" :loading="loading" :disabled="loading" :fab="$vuetify.breakpoint.xs" v-if="bookmarked">
                  <v-badge bordered :content="user.counts.bookmarks" color="pink" offset-y="0">
                    <span v-show="bookmarked"><v-icon class="mr-1">mdi-heart</v-icon>
                      <span v-show="!$vuetify.breakpoint.xs">Bookmarked</span>
                    </span>
                  </v-badge>
                </v-btn>
                <v-btn color="pink" outlined class="mb-5 mb-md-0" large @click="toggleBookmark('add')" :loading="loading" :disabled="loading" :fab="$vuetify.breakpoint.xs" v-else>
                  <v-badge :content="user.counts.bookmarks ? user.counts.bookmarks : '0'" color="pink" offset-y="0">
                    <span v-show="!bookmarked"><v-icon class="mr-1">mdi-heart</v-icon>
                      <span v-show="!$vuetify.breakpoint.xs">Bookmark Profile</span>
                    </span>
                  </v-badge>
                </v-btn>
              </div>
            </div>
            <v-btn class="mb-5 mb-md-0 ml-4" large fab small color="primary" outlined v-if="isMe && $vuetify.breakpoint.xs" :to="{name: 'editProfile'}"><v-icon>mdi-pencil</v-icon></v-btn>
          </div>
          <!--          <v-menu v-model="menu" offset-y>-->
          <!--            <template v-slot:activator="{ on, attrs }">-->
          <!--              <v-btn color="primary" fab depressed outlined small class="align-self-center" v-bind="attrs" v-on="on">-->
          <!--                <v-icon small>mdi-dots-horizontal</v-icon>-->
          <!--              </v-btn>-->
          <!--            </template>-->
          <!--            <v-card>-->
          <!--              <v-card-actions>-->
          <!--                <v-btn small color="error" text @click="menu = false">Delete</v-btn>-->
          <!--              </v-card-actions>-->
          <!--            </v-card>-->
          <!--          </v-menu>-->
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="user.profile.skill.length > 0"></v-divider>
      <v-list-item v-if="user.profile.skill.length > 0">
        <v-list-item-content>
          <div>
            <v-chip v-for="(skill, i) in user.profile.skill" :key="i" class="mr-3 mt-3" color="info" small outlined>{{ skill.name }}</v-chip>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import breakPoints from '../../mixins/breakPoints'
import SendMessage from './SendMessage'
import ADD_BOOKMARK from '../../graphql/mutations/addBookmark.graphql'
import DELETE_BOOKMARK from '../../graphql/mutations/deleteBookmark.graphql'
import USER from '../../graphql/queries/user.graphql'
const UPDATE_USER = require('../../graphql/updateUser.graphql')

export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    currentUser: {
      type: Object,
      default: () => {}
    },
    isMe: {
      type: Boolean,
      default: false
    },
    registerStatus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menu: false,
      loading: false,
      uploading: false,
      snackbar: false,
      uploadMessage: ''
    }
  },
  computed: {
    bookmarked () {
      return this.user.isBookmarked
    },
    isGuest () {
      return Object.keys(this.currentUser).length === 0 && this.currentUser.constructor === Object
    }
  },
  methods: {
    async toggleBookmark (type) {
      if (this.isMe) {
        return
      }
      try {
        this.loading = true
        await this.$apollo.mutate({
          mutation: type === 'add' ? ADD_BOOKMARK : DELETE_BOOKMARK,
          variables: {
            id: type === 'add' ? this.user.ID : this.user.isBookmarked
          },
          update: (store, { data: { addBookmark } }) => {
            const query = {
              query: USER,
              variables: {
                id: this.user.ID
              }
            }
            const data = store.readQuery(query)
            if (type === 'add') {
              data.user.isBookmarked = addBookmark
              data.user.counts.bookmarks++
            } else {
              data.user.isBookmarked = null
              data.user.counts.bookmarks--
            }
            store.writeQuery({
              ...query,
              data
            })
          }
        })
      } catch (e) {
        return e
      } finally {
        this.loading = false
      }
    },
    async uploadImage (file) {
      if (file.size > 2000000) {
        this.uploadMessage = 'File Size should be less than 2 MB.'
        this.snackbar = true
        return
      }
      try {
        this.uploading = true
        await this.$apollo.mutate({
          mutation: UPDATE_USER,
          variables: {
            data: {
              avatar: file
            }
          }
        })
        this.$emit('editClicked')
        this.uploadMessage = 'Photo Uploaded Successfully, Updating...'
      } catch (e) {
        this.uploadMessage = 'Photo Uploaded Successfully!'
        return e
      } finally {
        this.snackbar = true
        this.uploading = false
      }
    }
  },
  components: {
    SendMessage
  },
  mixins: [breakPoints]
}
</script>
<style lang="scss">
  .uploader {
    &:hover {
      .dp-edit {
        background-color: var(--v-accent-base);;
        opacity: 0.8;
      }
    }
  }
  .dp-edit {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    .file-upload {
      button.v-icon {
        height: 100%;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
      }
    }
  }
</style>
