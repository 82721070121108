<template>
  <div>
    <v-card v-if="$apollo.queries.currentUser.loading" flat class="py-sm-5 px-sm-1 pa-0">
      <v-skeleton-loader type="list-item-two-line" class="mb-5"></v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader type="list-item-two-line" class="my-5"></v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader type="list-item-two-line" class="my-5"></v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader type="list-item-two-line" class="my-5"></v-skeleton-loader>
    </v-card>
    <div v-else>
      <v-card flat>
        <v-list v-for="(item, i) in profiles" :key="i" class="pa-0">
          <v-list-item :to="{ name: 'profile', params: { slug: item.profile.data.display_name,  id: item.profile.ID } }" flat :tile="i !== profiles.length - 1" hover>
            <v-list-item-avatar size="40">
              <v-img :src="item.profile.data.meta.et_avatar_url" :lazy-src="item.profile.data.meta.et_avatar_url" v-if="item.profile.data.meta.et_avatar_url"></v-img>
              <span class="white--text" v-else>{{ item.profile.data.meta.first_name.toUpperCase() }}{{ item.profile.data.meta.last_name.toUpperCase() }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-break pb-1">{{ item.profile.data.meta.first_name }} {{ item.profile.data.meta.last_name }} <span v-show="item.profile.data.role === 'employer'" class="caption text-uppercase black--text font-weight-medium yellow px-1 rounded">Employer</span></v-list-item-title>
              <v-list-item-subtitle class="text-break pb-1 text--disabled" v-if="item.profile.data.profile.college.length > 0">{{ item.profile.data.profile.college[0].name }}</v-list-item-subtitle>
              <v-list-item-subtitle class="pb-2"><span class="success--text text-break font-weight-medium">Saved {{ item.date | moment('from', 'now') }}</span></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-show="i < profiles.length - 1"></v-divider>
          <v-divider v-show="i < profiles.length - 1"></v-divider>
        </v-list>
      </v-card>
      <v-card-text class="text-h6" v-if="profiles.length === 0">
        <div>
          😐 You have not saved any profile yet.
        </div>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import BOOKMARKED_PROFILES from '../../graphql/queries/bookmarkedProfiles.graphql'
export default {
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  apollo: {
    currentUser: {
      query: BOOKMARKED_PROFILES,
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    profiles () {
      return this.currentUser && this.currentUser.myBookmarks.filter(bookmark => bookmark.profile !== null)
    }
  }
}
</script>
